<template>
  <div class="flex justify-between items-center" @click="changeValue">
    <div
      style="width: 3.7rem"
      class="h-8 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
      :class="{ 'bg-green-400': is }"
    >
      <div
        class="bg-white w-8 h-8 rounded-full shadow-md transform duration-300 ease-in-out"
        :class="{ 'translate-x-6': is }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    followup: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      is: false
    };
  },
  created() {
    this.is = this.followup;
    this.$forceUpdate();
  },
  methods: {
    changeValue: function() {
      this.$emit("update:followup", (this.is = !this.is));
    }
  }
};
</script>

<style lang="scss">
.toggle-checkbox:checked {
  @apply right-0;
  right: 0;
}
.toggle-checkbox:checked + .toggle-label {
  @apply bg-green-400;
  background-color: #68d391;
}
</style>
